import { defineStore } from "pinia"
import { useNuxtApp } from "#app"
import {
  TimelineGroupId,
  TimelineGroup,
  Camera,
  Project,
  CameraFeatureFlag,
  AnalyticsEvent,
  TimelineAnprUrlParams,
} from "@evercam/shared/types"
import { TimelineColors } from "@evercam/shared/constants/timeline"
import { TimelineChartType } from "@evercam/ui"
import {
  TimelineAnprProvider,
  TimelineDataProvider,
} from "@evercam/shared/components/timelinePlayer/providers"
import { useAccountStore } from "@/stores/account"
import { useProjectStore } from "@/stores/project"

const groupId = TimelineGroupId.Anpr

interface TimelineAnprGroupState {
  showAnpr: boolean
}

export const useTimelineAnprGroupStore = defineStore({
  id: "timelineAnprGroup",
  state: (): TimelineAnprGroupState => ({
    showAnpr: true,
  }),
  getters: {
    selectedProject(): Project {
      return useProjectStore().selectedProject as Project
    },
    cameras(): Camera[] {
      return this.selectedProject.cameras as Camera[]
    },
    anprCamera(): Camera | null {
      return this.cameras.find((c) =>
        c.featureFlags.includes(CameraFeatureFlag.ANPR)
      )
    },
    anprEventsProvider(): TimelineDataProvider {
      return new TimelineAnprProvider({
        projectExid: useProjectStore().selectedProject?.exid,
        cameraExid: this.anprCamera?.id,
        token: useAccountStore().token,
        timezone: this.selectedProject?.timezone,
      })
    },
    isDisabled(): boolean {
      return !this.anprCamera
    },
    isVisible(): boolean {
      return this.showAnpr && !this.isDisabled
    },
    group(): TimelineGroup {
      return {
        id: groupId,
        isDisabled: this.isDisabled,
        isVisible: this.isVisible,
        timelineConfig: {
          label: useNuxtApp()
            .vue2App.$i18n.t(`content.etimeline.labels.${groupId}`)
            .toString(),
          color: TimelineColors.anpr,
          chartType: TimelineChartType.dots,
          height: 38,
          dotsSize: 16,
          provider: this.anprEventsProvider,
        },
      }
    },
  },
  actions: {
    changeAnprVisibility(visibilityStatus) {
      if (this.showAnpr === visibilityStatus) {
        return
      }
      this.showAnpr = visibilityStatus
      useNuxtApp().nuxt2Context.$analytics.saveEvent(
        AnalyticsEvent.groupsVisibilityAnpr,
        { visible: visibilityStatus }
      )
    },
  },
  syncWithUrl: Object.values(
    TimelineAnprUrlParams
  ) as (keyof TimelineAnprGroupState)[],
})
