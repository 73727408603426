import { defineStore } from "pinia"
import {
  TimelineGroupId,
  TimelineGroup,
  ProjectSmartMilestone,
  TimelinePlayerGroupConfig,
  AnalyticsEvent,
  TimelineSmartMilestonesUrlParams,
} from "@evercam/shared/types"
import { useNuxtApp } from "#app"

const groupId = TimelineGroupId.SmartMilestones

interface TimelineSmartMilestonesGroupState {
  showSmartMilestones: boolean
  smartMilestones: ProjectSmartMilestone[]
}

export const useTimelineSmartMilestonesGroupStore = defineStore({
  id: "timelineSmartMilestonesGroup",
  state: (): TimelineSmartMilestonesGroupState => ({
    showSmartMilestones: false,
    smartMilestones: [] as ProjectSmartMilestone[],
  }),
  getters: {
    hasSmartMilestones() {
      // @ts-ignore
      return useNuxtApp().nuxt2Context.$permissions.project.has.smartMilestones()
    },
    isDisabled(): boolean {
      return !this.hasSmartMilestones
    },
    isVisible(): boolean {
      return this.showSmartMilestones && !this.isDisabled
    },
    group(): TimelineGroup {
      return {
        id: groupId,
        isDisabled: this.isDisabled,
        isVisible: this.isVisible,
        timelineConfig: {} as TimelinePlayerGroupConfig,
      }
    },
  },
  actions: {
    changeSmartMilestonesVisibility(visibilityStatus) {
      if (this.showSmartMilestones === visibilityStatus) {
        return
      }
      this.showSmartMilestones = visibilityStatus
      useNuxtApp().nuxt2Context.$analytics.saveEvent(
        AnalyticsEvent.groupsVisibilitySmartMilestones,
        { visible: visibilityStatus }
      )
    },
  },
  syncWithUrl: Object.values(
    TimelineSmartMilestonesUrlParams
  ) as (keyof TimelineSmartMilestonesGroupState)[],
})
