import { defineStore } from "pinia"
import {
  TimelineGroupId,
  TimelineGroup,
  TimelineMediaHubItemsByType,
  Media,
  AnalyticsEvent,
  TimelineMediaUrlParams,
} from "@evercam/shared/types"
import {
  TimelineColors,
  TimelineSharedConfig,
} from "@evercam/shared/constants/timeline"
import { TimelineEvent } from "@evercam/ui"
import moment from "moment-timezone"
import { useNuxtApp } from "#app"

const groupId = TimelineGroupId.Media

const pickXItemsPerType = (
  mediaHubItems: TimelineMediaHubItemsByType,
  x = 4
): TimelineMediaHubItemsByType => {
  const selectEvenlySpacedItems = (mediaItems: Media[]) => {
    if (mediaItems.length <= x) {
      return mediaItems
    }

    return Array.from(
      { length: x },
      (_, index) =>
        mediaItems[Math.floor((index / (x - 1)) * (mediaItems.length - 1))]
    )
  }

  return Object.keys(mediaHubItems).reduce((acc, key) => {
    return {
      ...acc,
      [key]: selectEvenlySpacedItems(mediaHubItems[key]),
    }
  }, {})
}

interface TimelineMediaHubGroupState {
  showMedia: boolean
  mediaHubItemsByType: TimelineMediaHubItemsByType
}

export const useTimelineMediaHubGroupStore = defineStore({
  id: "timelineMediaHubGroup",
  state: (): TimelineMediaHubGroupState => ({
    showMedia: false,
    mediaHubItemsByType: {
      clip: [],
      compare: [],
      edit: [],
      timelapse: [],
      xRay: [],
    },
  }),
  getters: {
    hasMediaHubItems(): boolean {
      return Object.values(this.mediaHubItemsByType).some(
        (items) => items.length > 0
      )
    },
    isDisabled(): boolean {
      return !this.hasMediaHubItems
    },
    isVisible(): boolean {
      return this.showMedia && !this.isDisabled
    },
    group(): TimelineGroup {
      const groupConfig = {
        id: groupId,
        isDisabled: this.isDisabled,
        isVisible: this.isVisible,
      }

      if (this.isDisabled) {
        return groupConfig as TimelineGroup
      }

      const mediaHubMilestones = Object.values(
        pickXItemsPerType(this.mediaHubItemsByType)
      ) as Media[][]
      const events = mediaHubMilestones.reduce((acc, mediaItems: Media[]) => {
        return [
          ...acc,
          ...mediaItems.map((a) => {
            return {
              ...a,
              milestoneType: TimelineGroupId.Media,
              color: TimelineColors.mediaHubMilestone,
              timestamp: moment(a.fromDate).toISOString(),
              text: a.title,
              thumbnailUrl:
                useNuxtApp().nuxt2Context.$imgproxy.get360pResizedImageUrl(
                  a?.url
                ),
            }
          }),
        ]
      }, []) as unknown as TimelineEvent[]

      return {
        ...groupConfig,
        timelineConfig: {
          ...TimelineSharedConfig,
          color: TimelineColors.mediaHubMilestone,
          events,
        },
      }
    },
  },
  actions: {
    changeMediaHubVisibility(visibilityStatus) {
      if (this.showMedia === visibilityStatus) {
        return
      }
      this.showMedia = visibilityStatus
      useNuxtApp().nuxt2Context.$analytics.saveEvent(
        AnalyticsEvent.groupsVisibilityMediaHub,
        { visible: visibilityStatus }
      )
    },
  },
  syncWithUrl: Object.values(
    TimelineMediaUrlParams
  ) as (keyof TimelineMediaHubGroupState)[],
})
