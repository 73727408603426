import { defineStore } from "pinia"
import {
  TimelineGroupId,
  TimelineGroup,
  MobileCaptureSnapshot,
  AnalyticsEvent,
  TimelineMobileCaptureUrlParams,
} from "@evercam/shared/types"
import {
  TimelineColors,
  TimelineSharedConfig,
} from "@evercam/shared/constants/timeline"
import { useNuxtApp } from "#app"

const groupId = TimelineGroupId.MobileCapture

interface TimelineMobileCaptureGroupState {
  showMobileCapture: boolean
  mobileCaptureSnapshots: MobileCaptureSnapshot[]
}

export const useTimelineMobileCaptureGroupStore = defineStore({
  id: "timelineMobileCaptureGroup",
  state: (): TimelineMobileCaptureGroupState => ({
    showMobileCapture: false,
    mobileCaptureSnapshots: [] as MobileCaptureSnapshot[],
  }),
  getters: {
    isDisabled(): boolean {
      return !this.mobileCaptureSnapshots?.length
    },
    isVisible(): boolean {
      return this.showMobileCapture && !this.isDisabled
    },
    group(): TimelineGroup {
      const groupConfig = {
        id: groupId,
        isDisabled: this.isDisabled,
        isVisible: this.isVisible,
      }

      if (this.isDisabled) {
        return groupConfig as TimelineGroup
      }

      const mobileCaptureMilestones = this.mobileCaptureSnapshots.map((m) => {
        return {
          ...m,
          milestoneType: TimelineGroupId.MobileCapture,
          color: TimelineColors.mobileCaptureMilestone,
          timestamp: m.updatedAt,
          text: `Mobile Snapshot: ${m.uploadedBy}`,
          thumbnailUrl: m.thumbnailUrl,
        }
      })

      return {
        ...groupConfig,
        timelineConfig: {
          ...TimelineSharedConfig,
          color: TimelineColors.mobileCaptureMilestone,
          events: mobileCaptureMilestones,
        },
      }
    },
  },
  actions: {
    changeMobileCaptureVisibility(visibilityStatus) {
      if (this.showMobileCapture === visibilityStatus) {
        return
      }
      this.showMobileCapture = visibilityStatus
      useNuxtApp().nuxt2Context.$analytics.saveEvent(
        AnalyticsEvent.groupsVisibilityMobileCapture,
        { visible: visibilityStatus }
      )
    },
  },
  syncWithUrl: Object.values(
    TimelineMobileCaptureUrlParams
  ) as (keyof TimelineMobileCaptureGroupState)[],
})
