import { defineStore } from "pinia"
import { useNuxtApp } from "#app"
import moment from "moment-timezone"
import {
  TimelineGroupId,
  TimelineGroup,
  Comment,
  CommentCreationRequestPayload,
  AnalyticsEvent,
  TimelineCommentsUrlParams,
} from "@evercam/shared/types"
import {
  TimelineColors,
  TimelineSharedConfig,
} from "@evercam/shared/constants/timeline"
import { EvercamApi } from "@evercam/shared/api/evercamApi"
import { isDateWithinRange } from "@evercam/shared/utils"
import { useTimelineStore } from "@/stores/timeline/timeline"
import { useLayoutStore } from "@/stores/layout"
import { useAccountStore } from "@/stores/account"

const groupId = TimelineGroupId.Comments

interface TimelineCommentsGroupState {
  isAnnotationActive: boolean
  showComments: boolean
  comments: Comment[]
  visibleComments: Comment[]
  selectedComment: Comment
  newCommentData: CommentCreationRequestPayload
}

export const useTimelineCommentsGroupStore = defineStore({
  id: "timelineCommentsGroup",
  state: (): TimelineCommentsGroupState => ({
    isAnnotationActive: false,
    showComments: false,
    comments: [] as Comment[],
    visibleComments: [] as Comment[],
    selectedComment: null as Comment,
    newCommentData: null as CommentCreationRequestPayload,
  }),
  getters: {
    hasComments(state) {
      return state.comments.length > 0
    },
    isDisabled(): boolean {
      return !this.hasComments
    },
    isVisible(): boolean {
      return this.showComments && !this.isDisabled
    },
    group(): TimelineGroup {
      const comments = this.comments.map((d) => {
        return {
          ...d,
          milestoneType: TimelineGroupId.Comments,
          color: TimelineColors.comments,
          timestamp: d.timestamp,
          text: d.content,
        }
      })

      return {
        id: groupId,
        isDisabled: this.isDisabled,
        isVisible: this.isVisible,
        timelineConfig: {
          ...TimelineSharedConfig,
          label: useNuxtApp()
            .vue2App.$i18n.t(`content.etimeline.labels.${groupId}`)
            .toString(),
          color: TimelineColors.comments,
          dotsSize: 1,
          events: comments,
        },
      }
    },
  },

  actions: {
    async addComment(project, comment: CommentCreationRequestPayload) {
      try {
        const newComment = await EvercamApi.comments.addComment(
          project,
          comment
        )
        this.comments.unshift(newComment)
        await this.filterCommentsByInterval(
          useTimelineStore().snapshotsInterval
        )
        this.selectedComment = { ...newComment, canDelete: true }
        useNuxtApp().nuxt2Context.$notifications.success(
          useNuxtApp().vue2App.$i18n.t(
            "content.comments.create_success"
          ) as string
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t(
            "content.comments.create_failed"
          ) as string,
          error,
        })
      }
    },
    async deleteComment(project, commentId) {
      try {
        await EvercamApi.comments.deleteComment(project, commentId)
        this.comments = this.comments.filter(
          (comment) => comment?.id !== commentId
        )
        this.filterCommentsByInterval(useTimelineStore().snapshotsInterval)
        useNuxtApp().nuxt2Context.$notifications.success(
          useNuxtApp().vue2App.$i18n.t(
            "content.comments.delete_success"
          ) as string
        )
      } catch (error) {
        useNuxtApp().nuxt2Context.$notifications.error({
          text: useNuxtApp().vue2App.$i18n.t(
            "content.comments.delete_failed"
          ) as string,
          error,
        })
      }
    },
    filterCommentsByInterval(snapshotInterval) {
      this.visibleComments = this.comments.filter(
        (comment) =>
          isDateWithinRange(
            comment.timestamp,
            snapshotInterval.from,
            snapshotInterval.to
          ) &&
          (!comment.cameraExid ||
            comment.cameraExid === useTimelineStore().cameraExid)
      )
    },
    selectComment(comment) {
      const targetTime = moment(comment.timestamp).toISOString()
      const canDelete = comment.creatorEmail === useAccountStore().email
      useTimelineStore().selectTimestamp(targetTime)
      if (comment.cameraExid !== useTimelineStore().cameraExid) {
        useTimelineStore().selectCamera(comment.cameraExid)
      }
      this.selectedComment = { ...comment, canDelete }
      useLayoutStore().enableRightSidebar()
      useNuxtApp().nuxt2Context.$analytics.saveEvent(
        AnalyticsEvent.commentsSelect
      )
    },
    changeCommentsVisibility(visibilityStatus) {
      if (this.showComments === visibilityStatus) {
        return
      }
      this.showComments = visibilityStatus
      useNuxtApp().nuxt2Context.$analytics.saveEvent(
        AnalyticsEvent.groupsVisibilityComments,
        { visible: visibilityStatus }
      )
    },
    allowComment(isActive) {
      if (this.isAnnotationActive === isActive) {
        return
      }
      this.isAnnotationActive = isActive
      useNuxtApp().nuxt2Context.$analytics.saveEvent(AnalyticsEvent.comment, {
        active: isActive,
      })
    },
  },
  syncWithUrl: Object.values(
    TimelineCommentsUrlParams
  ) as (keyof TimelineCommentsGroupState)[],
})
