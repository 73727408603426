import { defineStore } from "pinia"
import {
  TimelineGroupId,
  TimelineGroup,
  AnalyticsEvent,
  TimelineExNvrRecordingsUrlParams,
} from "@evercam/shared/types"
import { TimelineColors } from "@evercam/shared/constants/timeline"
import { useNuxtApp } from "#app"
import { useRecordingsStore } from "@/stores/recordings"

const groupId = TimelineGroupId.ExNvrRecordings

interface TimelineExNvrGroupState {
  showExNvrRecordings: boolean
}

export const useTimelineExNvrGroupStore = defineStore({
  id: "timelineExNvrGroup",
  state: (): TimelineExNvrGroupState => ({
    showExNvrRecordings: false,
  }),
  getters: {
    $permissions() {
      // @ts-ignore
      return useNuxtApp().nuxt2Context.$permissions
    },
    hasEdgeVideoStreaming(): boolean {
      return this.$permissions.camera.has.edgeVideoStreaming()
    },
    isDisabled(): boolean {
      return !this.hasEdgeVideoStreaming
    },
    isVisible(): boolean {
      return this.showExNvrRecordings && !this.isDisabled
    },
    isRestricted(): boolean {
      return !this.$permissions.user.is.admin() || this.isDisabled
    },
    group(): TimelineGroup {
      return {
        id: groupId,
        isDisabled: this.isDisabled,
        isVisible: this.isVisible,
        isRestricted: this.isRestricted,
        timelineConfig: {
          label: useNuxtApp()
            .vue2App.$i18n.t(`content.etimeline.labels.${groupId}`)
            .toString(),
          color: TimelineColors.exNvr,
          height: 16,
          events: useRecordingsStore().exNvrAvailableRecordingsIntervals,
        },
      }
    },
  },
  actions: {
    changeExNvrRecordingsVisibility(visibilityStatus) {
      if (this.showExNvrRecordings === visibilityStatus) {
        return
      }
      this.showExNvrRecordings = visibilityStatus
      useNuxtApp().nuxt2Context.$analytics.saveEvent(
        AnalyticsEvent.groupsVisibilityExNvrVideoRecordings,
        { visible: visibilityStatus }
      )
    },
  },
  syncWithUrl: Object.values(
    TimelineExNvrRecordingsUrlParams
  ) as (keyof TimelineExNvrGroupState)[],
})
