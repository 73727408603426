import { defineStore } from "pinia"
import { useNuxtApp } from "#app"
import {
  AnalyticsEvent,
  Camera,
  CameraFeatureFlag,
  Project,
  TimelapseReportType,
  TimelineConstructionReportsUrlParams,
  TimelineGroup,
  TimelineGroupId,
  TimelinePlayerGroupConfig,
} from "@evercam/shared/types"
import { TimelineColors } from "@evercam/shared/constants/timeline"
import { useAccountStore } from "@/stores/account"
import { useProjectStore } from "@/stores/project"
import { TimelineTimelapseReportsProvider } from "@evercam/shared/components/timelinePlayer/providers"

const groupId = TimelineGroupId.ConstructionReports

interface TimelineConstructionReportsGroup {
  showConstructionReports: boolean
}

export const useTimelineConstructionReportsGroupStore = defineStore({
  id: "timelineConstructionReportsGroup",
  state: (): TimelineConstructionReportsGroup => ({
    showConstructionReports: true,
  }),
  getters: {
    $permissions() {
      // @ts-ignore
      return useNuxtApp().nuxt2Context.$permissions
    },
    selectedProject(): Project {
      return useProjectStore().selectedProject as Project
    },
    cameras(): Camera[] {
      return this.selectedProject.cameras as Camera[]
    },
    timelapseReportsCamera(): Camera | null {
      return this.cameras.find((c) =>
        c.featureFlags.includes(CameraFeatureFlag.CopilotTimelapseReport)
      )
    },
    isDisabled(): boolean {
      return !this.timelapseReportsCamera
    },
    isVisible(): boolean {
      return this.showConstructionReports && !this.isDisabled
    },
    isRestricted(): boolean {
      return !this.$permissions.user.is.admin() || this.isDisabled
    },
    constructionReportsGroup(): Record<string, TimelinePlayerGroupConfig> {
      return {
        label: useNuxtApp()
          .vue2App.$i18n.t("content.etimeline.labels.constructionReports")
          .toString(),
        color: TimelineColors.constructionReport,
        provider: new TimelineTimelapseReportsProvider({
          reportType: TimelapseReportType.construction,
          projectExid: useProjectStore().selectedProject?.exid,
          token: useAccountStore().token,
          timezone: this.selectedProject?.timezone,
          countsFilter: (id) => id === TimelapseReportType.construction,
        }),
      }
    },
    group(): TimelineGroup {
      return {
        id: groupId,
        isDisabled: this.isDisabled,
        isVisible: this.isVisible,
        isRestricted: this.isRestricted,
        timelineConfig: this.constructionReportsGroup,
      }
    },
  },
  actions: {
    changeConstructionReportsVisibility(visibilityStatus) {
      if (this.showConstructionReports === visibilityStatus) {
        return
      }
      this.showConstructionReports = visibilityStatus
      useNuxtApp().nuxt2Context.$analytics.saveEvent(
        AnalyticsEvent.groupsVisibilityConstructionReport,
        { visible: visibilityStatus }
      )
    },
  },
  syncWithUrl: Object.values(
    TimelineConstructionReportsUrlParams
  ) as (keyof TimelineConstructionReportsGroup)[],
})
